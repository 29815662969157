<template>
  <div>
    <p>
      The path was pointing to a legacy page. <br>
      Kindly refer to [ router.js ] to determine the original functionality of this page
      <br><br>
      <span style="color: var(--status-blue);">{{ $route.fullPath }}</span>
    </p>

    <!-- The following is a short example on how to use these components. refer to components in LegalCopilot/pre_trial for complex usage -->
    <div style="display: flex; color: white; padding: 10px; width: 100%;">
      <FileInput
        style="flex-grow: 1; margin-right: 20px;"
        :accept="['pdf']"
        @input-files="$set(files, 0, $event)"
      />
      <FileProcess
        style="width: 40%; max-height: 200px; overflow-y: auto;"
        :accept="['pdf']"
        :files="files[0]"
        @output-files="$set(files, 1, $event)"
      />
    </div>

    <LabelSwitch tag="Client Case" :label="tabs" :def="tab" @switch="tab = $event" />
    <LabelSwitch tag="Witness" :label="tabs" :def="tab1" @switch="tab1 = $event" />

    {{ tab }} {{ tab1 }}
  </div>
</template>

<script>
import FileInput from '@/components/input/FileInput.vue';
import FileProcess from '../components/input/FileProcess.vue';
import LabelSwitch from '../components/input/LabelSwitch.vue';
export default {
  components: { FileInput, FileProcess, LabelSwitch },

  data() { return {
    files: [[], []],

    tabs: ['Upload File', 'Input Manually'],
    tab: 0,
    tab1: 0,
  }},
}
</script>